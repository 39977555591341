<script setup>
import { useSupportStore } from '~/store/support'
import { SQUAD_01_20240903_MIGRATION_HIRE_REGISTER_CREATE_OFFER } from '~/config/featureFlags.js'

defineOptions({
	name: 'FormHireRegistration',
})

const props = defineProps({
	origin: {
		type: String,
		default: 'register',
	},
	params: {
		type: Object,
		default: null,
	},
	hiddenFields: {
		type: Array,
		default: () => [],
	},
})

const emit = defineEmits(['submit', 'submit-error', 'submit-success'])

const initialForm = {
	origin: '',
	business_user_name: '',
	business_user_lastname: '',
	country_code: null,
	phone_number: '',
	email: '',
	password: '',
	confirm_password: '',
	name: '',
	business_size_id: '',
	service_initial_id: '',
	uuid: null,
	referral_source_id: null,
	referral_source_other_description: null,
}

const initialErrors = {
	business_user_name: '',
	business_user_lastname: '',
	country_code: '',
	phone_number: '',
	email: '',
	password: '',
	confirm_password: '',
	name: '',
	business_size_id: '',
	referral_source_other_description: '',
}

const options = ref({
	referral_sources_hire: [],
	business_sizes: [],
})

const { fetchSupport } = useSupportStore()
const { getResource } = storeToRefs(useSupportStore())
const { $tracking, $repository, $auth, $toast, $hasFeature } = useNuxtApp()
const { t: $t, locale } = useI18n()
const route = useRoute()
const router = useRouter()
const config = useRuntimeConfig()
const localePath = useLocalePath()

const phoneValid = ref(false)
const form = ref({ ...initialForm })
const business_size_id = ref(null)
const accept_terms = ref(false)
const errors = ref({ ...initialErrors })
const defaultCountryCode = ref('PE')
const loadingForm = ref(false)
const isSetPhone = ref(false)
const referral_source_id = ref(null)

const setCountryCode = async () => {
	const response = await fetch(`https://ip2c.org/s`)
	const text = await response.text()

	const result = (text || '').toString()

	if (!result || result[0] !== '1') {
		defaultCountryCode.value = 'PE'
	}

	defaultCountryCode.value = result.substr(2, 2)
}

const setParams = () => {
	setCountryCode()

	form.value.origin = props.origin
	form.value.service_initial_id = route.query?.service || '8'

	if (props.params === null) return

	const {
		business_size_id: businessSizeId,
		business_user_name,
		country_code,
		name,
		email,
		phone_number,
		uuid,
	} = props.params

	// Valores de formulario
	form.value.business_size_id = businessSizeId || null
	form.value.business_user_name = business_user_name || ''
	form.value.country_code = country_code || null
	form.value.name = name || ''
	form.value.business_user_name = business_user_name || ''
	form.value.email = email || ''
	form.value.phone_number = phone_number || ''
	form.value.uuid = uuid || null

	// Valores por defecto
	isSetPhone.value = true
	business_size_id.value = getResource.value('business_sizes').find((size) => size.id === businessSizeId) || null
}

const validatePhone = (val) => {
	phoneValid.value = val
}

const phoneValidate = (phoneObject) => {
	if (phoneObject.valid !== undefined) {
		errors.value.phone_number = phoneObject.valid ? '' : $t('Número de teléfono inválido')
	}
}

const updateForm = (value, property) => {
	if (value && value.value) {
		form.value[property] = value?.value
	}
}

const validationForm = () => {
	if (!phoneValid.value) {
		errors.value.phone_number = $t('Número de teléfono inválido')

		nextTick(() => {
			document.querySelector('#phone').focus()
		})

		return true
	}

	const errorsTemp = { ...errors.value }
	Object.keys(errorsTemp).forEach((key) => {
		errors.value[key] = ''
	})

	if (form.value.password !== form.value.confirm_password) {
		errors.value.confirm_password = $t('Las contraseñas no coinciden')
		nextTick(() => {
			document.querySelector('#confirm_password').focus()
		})
		return true
	} else {
		errors.value.confirm_password = ''
	}

	return false
}

const checkPasswordMatch = () => {
	if (form.value.password === form.value.confirm_password) {
		errors.value.confirm_password = ''
	}
}

const sendForm = async (form) => {
	emit('submit', form)

	loadingForm.value = true
	const { success, errors: serverErrors, result, message } = await $repository.v3.hire.postRegisterCompany(form)

	if (!success) {
		loadingForm.value = false
		Object.keys(serverErrors).forEach((item) => {
			errors.value[item] = serverErrors[item][0]
		})

		emit('submit-error', result)
		$toast.error(message)
		return
	}

	$tracking.register.eventRegisterB2B(form)
	emit('submit-success', result)

	// Iniciando Sesión
	await login()
}

const login = async () => {
	const { success, message } = await $auth.login({
		email: form.value.email,
		password: form.value.password,
	})

	if (success) {
		const { success } = await $auth.fetchMe()

		if (success) {
			loadingForm.value = false
			$tracking.auth.identify()

			if ($hasFeature(SQUAD_01_20240903_MIGRATION_HIRE_REGISTER_CREATE_OFFER)) {
				await router.push(localePath('/offer/create?from=hire'))
			} else {
				await router.push(localePath('/offer/import'))
			}

			return
		}
	}

	if (!success) {
		loadingForm.value = false
		$toast.error(message)
	}
}

const submitForm = () => {
	if (validationForm()) return

	$tracking.register.registerFormClicked()

	const utmCookie = useCookie(config.public.utm.cookie_name, {
		domain: config.public.utm.cookie_domain,
	})

	const params = {
		...form.value,
		...(utmCookie.value || {}),
		utm_uuid: $tracking.identity.getAnonymousId() || null,
		locale: locale.value,
	}

	sendForm(params)
}

onMounted(async () => {
	await fetchSupport(['business_sizes', 'referral_sources_hire'])

	options.value = {
		referral_sources_hire: getResource.value('referral_sources_hire').map((item) => ({
			label: item.name,
			value: item.id,
		})),
		business_sizes: getResource.value('business_sizes').map((item) => ({
			label: item.name,
			value: item.id,
		})),
	}
	setParams()
})
</script>

<template>
	<slot name="header">
		<h1 class="text-left text-xl md:text-2xl text-primary font-semibold mb-10">
			{{ $t('Regístrate Gratis') }}
		</h1>
	</slot>
	<form
		class="form-hire-register grid grid-cols-1 2xl:grid-cols-2 gap-4"
		@submit.prevent="submitForm"
	>
		<UInput
			id="name"
			v-model="form.business_user_name"
			:label="$t('Nombre(s)')"
			required
			:placeholder="$t('Ingresar nombre(s)')"
			:message-danger="errors.business_user_name"
		/>

		<UInput
			id="lastname"
			v-model="form.business_user_lastname"
			:label="$t('Apellido(s)')"
			required
			:placeholder="$t('Apellido(s)')"
			:message-danger="errors.business_user_lastname"
		/>

		<UInput
			id="organization"
			v-model="form.name"
			class="col-span-full"
			:label="$t('Nombre de tu empresa')"
			:placeholder="$t('Ingresar nombre de empresa')"
			:message-danger="errors.name"
			required
		/>

		<ClientOnly>
			<UInputPhone
				id="phone"
				v-model:country="form.country_code"
				v-model:phone="form.phone_number"
				placeholder="999 999 999"
				:label="$t('Número de teléfono')"
				class="z-10 col-span-full"
				required
				@validate="validatePhone"
				@blur="phoneValidate"
				@country-changed="phoneValidate"
			/>
			<div
				v-if="errors.phone_number"
				class="text-sm text-red-500 mt-1.5"
			>
				{{ errors.phone_number }}
			</div>
		</ClientOnly>

		<UInput
			id="email"
			v-model="form.email"
			class="col-span-full"
			:label="$t('Correo corporativo')"
			type="email"
			placeholder="ejemplo@mail.com"
			:message-danger="errors.email"
			required
		/>

		<USelect
			v-model="referral_source_id"
			class="col-span-full"
			:label="$t('¿Cómo te enteraste de Talently?')"
			:placeholder="$t('Seleccionar')"
			:options="options.referral_sources_hire"
			@select="(event) => updateForm(event, 'referral_source_id')"
		/>
		<div
			v-if="referral_source_id && referral_source_id.value === 12"
			class="col-span-full"
		>
			<UInput
				id="referral_otro"
				v-model="form.referral_source_other_description"
				:label="$t('Otro')"
				:placeholder="$t('Escribe como te enteraste de nosotros.')"
				:message-danger="errors.referral_source_other_description"
				required
			/>
		</div>

		<UInputPassword
			id="password"
			v-model="form.password"
			:label="$t('Crea una contraseña')"
			:placeholder="$t('De 8 caracteres')"
			:message-danger="errors.password"
			required
			@input="checkPasswordMatch"
		/>

		<UInputPassword
			id="confirm_password"
			v-model="form.confirm_password"
			:label="$t('Confirma tu contraseña')"
			:placeholder="$t('De 8 caracteres')"
			:message-danger="errors.confirm_password"
			required
			@input="checkPasswordMatch"
		/>

		<div class="col-span-full pt-1">
			<UCheckbox
				v-model="accept_terms"
				required
				:ui="{ label: 'text-sm font-normal text-inherit' }"
			>
				<template #label>
					<span>
						<span class="opacity-60 mr-0.5"> {{ $t('He leído y acepto los ') }} </span>
						<a
							href="https://talently.tech/terminos-y-condiciones"
							class="hover:underline text-primary-500"
							target="_blank"
						>
							{{ $t('Términos y condiciones') }} </a
						>,
						<a
							href="https://talently.tech/politica-de-privacidad"
							class="hover:underline text-primary-500"
							target="_blank"
						>
							{{ $t('Política de privacidad y Cookies') }}
						</a>
					</span>
				</template>
			</UCheckbox>
		</div>

		<slot
			name="footer"
			:loading-form="loadingForm"
		>
			<div
				class="col-span-full flex flex-col-reverse sm:flex-row items-center justify-center space-y-4 sm:space-y-0 sm:space-x-4"
			>
				<NuxtLink
					to="/login"
					class="font-semibold hover:underline text-primary text-center text-xs underline mt-4 sm:mt-0 sm:w-1/2"
				>
					{{ $t('Ya tengo cuenta') }}
				</NuxtLink>

				<UButton
					type="submit"
					class="w-full sm:w-auto uppercase px-8"
					:ui="{ rounded: 'rounded-full' }"
					:loading="loadingForm"
					:disabled="loadingForm"
				>
					<span class="flex justify-center items-center text-center gap-2 w-full">
						{{ $t('Continuar') }}
						<Icon
							name="uil:arrow-circle-right"
							size="20"
							stroke-width="4"
						/>
					</span>
				</UButton>
			</div>
		</slot>
	</form>
</template>
