<script setup lang="ts">
const texts = ['Lead Flutter Developer', 'Fullstack developer - Perú']
const currentText = ref('')
const currentIndex = ref(0)
const charIndex = ref(0)
const typingSpeed = 100 // Speed of typing in milliseconds

const typeText = () => {
	if (charIndex.value < texts[currentIndex.value].length) {
		currentText.value += texts[currentIndex.value].charAt(charIndex.value)
		charIndex.value++
		setTimeout(typeText, typingSpeed)
	} else {
		setTimeout(() => {
			currentText.value = ''
			charIndex.value = 0
			currentIndex.value = (currentIndex.value + 1) % texts.length
			typeText()
		}, 2000) // Wait 2 seconds before starting the next text
	}
}

onMounted(() => {
	typeText()
})
</script>

<template>
	<div
		class="flex items-center justify-between gap-6 pl-4 pr-2 py-2 border border-gray-300 rounded-full text-gray-800 focus-within:outline-none focus-within:border-purple-500 bg-white shadow-xl"
	>
		<span>{{ currentText }}</span>
		<div>
			<span
				class="h-8 w-8 ml-2 bg-purple-500 text-white rounded-full hover:bg-purple-600 transition-colors cursor-pointer flex items-center justify-center"
			>
				<Icon
					name="mdi:magnify"
					class="text-white"
					width="24"
					height="24"
				></Icon>
			</span>
		</div>
	</div>
</template>
<style scoped></style>
